<script>
  export let text;
</script>

<style>
  header {
    background-color: azure;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
    height: 30px;
  }
</style>

<header>
  <h5>{text}</h5>
</header>
