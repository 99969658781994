import * as markdownPosts from "../../content/posts/*.md";
import { convertMd2Json } from "./functions";

console.info(markdownPosts);

const convertToViewModel = markdownPosts => {
  let postValues;
  if (!markdownPosts || !(postValues = Object.values(markdownPosts))) {
    return [];
  }
  return postValues.map(({ content, data }) => {
    return {
      content,
      ...data
    };
  });
};

export const getPosts = () => {
  const contentPosts = convertToViewModel(markdownPosts);
  return [...contentPosts];
};
