<script>
  import Header from "./header/Header.svelte";
  import Navigation from "./navi/Navigation.svelte";
  import Screen from "./screen/Screen.svelte";
  import Box from "./box/Box.svelte";
  import Router from "svelte-spa-router";
  import { routes, naviItems } from "./route/routes";
</script>

<Box>
  <Header text="Header" />
  <Navigation items={naviItems} />
  <Screen>
    <Router {routes} />
  </Screen>
</Box>
