<script>
  import { link } from "svelte-spa-router";
  import active from "svelte-spa-router/active";

  export let name;
  export let href;
  export let isBreadcrumb = false;
</script>

{#if !isBreadcrumb}
  <li class="nav-item">
    <a class="nav-link" use:active use:link {href}>{name}</a>
  </li>
{:else}
  <li class="breadcrumb-item">
    <a use:link {href}>{name}</a>
  </li>
{/if}
