import Home from "../home/Home.svelte";
import Configurator from "../../configurator/Configurator.svelte";
import NotFound from "./NotFound.svelte";

export const routes = {
  // Exact path
  "/": Home,

  // Using named parameters, with last being optional
  "/app": Configurator,

  // Catch-all
  // This is optional, but if present it must be the last
  "*": NotFound
};

export const naviItems = [
  { name: "Home", href: "/" },
  { name: "App", href: "/app" }
];
