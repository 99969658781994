<script>
  export let category;
  export let content;
</script>

<article>
  <header>
    <h2>{category}</h2>
  </header>
  <main>{content}</main>
</article>
