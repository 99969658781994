<script>
  import { createEventDispatcher } from "svelte";
  import { createCustomEvent } from "../../lib/functions";
  const dispatch = createEventDispatcher();
  const collapseOrExpand = subbar =>
    (subbar.className = `${subbar.className} show`.replace(/ show show/, ""));

  const handleNavbarTooglerBtnClick = ({ target: element }) => {
    const dataTargetValue = element.getAttribute("data-target");
    const navbarSupportedContentId = dataTargetValue.replace(/#/, "");
    const menu = document.getElementById(navbarSupportedContentId);
    menu.dispatchEvent(createCustomEvent("onNavbarTooglerBtnClick"));
  };
</script>

<button
  on:click={handleNavbarTooglerBtnClick}
  class="navbar-toggler"
  type="button"
  data-toggle="collapse"
  data-target="#navbarSupportedContent"
  aria-controls="navbarSupportedContent"
  aria-expanded="false"
  aria-label="Toggle navigation">
  <span class="navbar-toggler-icon" data-target="#navbarSupportedContent" />
</button>
