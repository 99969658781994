<script>
  import { getPosts } from "../../lib/data";
  import { convertToMatrix } from "../../lib/functions";
  import Teaser from "../doctype/Teaser.svelte";
  const postRows = convertToMatrix(getPosts(), 3);
</script>

<div class="container-fluid">
  {#each postRows as postsInRow}
    <div class="row">
      {#each postsInRow as post}
        <div class="col-sm text-left">
          <Teaser {...post} />
        </div>
      {/each}
    </div>
  {/each}
</div>
