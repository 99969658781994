import frontMatter from "@egoist/front-matter";

export const createCustomEvent = (eventName, payload = {}) => {
  let defaultData = {
    detail: {},
    bubbles: true,
    cancelable: true,
    composed: true
  };
  return new CustomEvent(eventName, { ...defaultData, ...payload });
};

export const createEvent = eventName => new Event(eventName);

export const isEmpty = value =>
  !value || value === undefined || value.trim() === "";

export const isNotEmpty = value => !isEmpty(value);

const convertArrayToObject = (array, delimiter = ":") => {
  return array.reduce((obj, item) => {
    const [key, value] = item.split(delimiter).map(s => s.trim());
    return { ...obj, ...{ [key]: value } };
  }, {});
};

export const convertMd2Json = (markdownAsString, delimiter = "---") => {
  if (isEmpty(markdownAsString)) {
    return { head: [], body: [] };
  }
  const result = frontMatter(markdownAsString, delimiter);
  const headItems = result.head.split(/\n/).map(s => s.trim());
  result.head = convertArrayToObject(headItems);
  return result;
};

export const convertToMatrix = (flatArray = [], columnSize = 3) => {
  return flatArray.reduce((acc, x, index) => {
    if (index % columnSize == 0) {
      acc.push([x]);
    } else {
      acc[acc.length - 1].push(x);
    }
    return acc;
  }, []);
};
