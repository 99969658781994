<script>
  import NavbarTooglerButton from "./NavbarTooglerButton.svelte";
  import NaviItem from "./NaviItem.svelte";
  export let items = [];

  const handleNavbarTooglerBtnClick = event => {
    const menu = document.getElementById("navbarSupportedContent");
    menu.className = `${menu.className} show`.replace(/ show show/, "");
  };
</script>

<style>
  nav {
    position: fixed;
    top: 30px;
    right: 0;
    left: 0;
    z-index: 1030;
  }
</style>

<nav class="navbar navbar-expand-sm bg-dark navbar-dark">
  <NavbarTooglerButton />

  <div
    class="collapse navbar-collapse"
    id="navbarSupportedContent"
    on:onNavbarTooglerBtnClick={handleNavbarTooglerBtnClick}>
    <ul class="navbar-nav mr-auto">
      {#each items as { name, href }}
        <NaviItem {name} {href} />
      {/each}
    </ul>
  </div>
</nav>
